.center-text-or-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.service-agree-card {
  background-color: #F5F5F5;
  border-radius: 6px;
}

.serviceAgree {
  position: relative;
  display: flex;
  align-items: center;
  color: #373737;
  border-radius: 4px;
}

.serviceAgree input[type="checkbox"] {
  position: absolute;
  float: left;
  margin-bottom: 10px;
}

.serviceAgree label {
  margin-left: 30px;
  height: auto;
}

.serviceAgree a {
  color: #85be57;
}
.serviceAgree input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  background-color: #ffffff; /* White background when unchecked */
  border: 1px solid #ccc;
  margin: 0 10px 0 0;
  //position: relative;
  cursor: pointer;
}

.serviceAgree input[type="checkbox"]:checked {
  background-color: #85be57; /* Background color when checked */
}

.serviceAgree input[type="checkbox"]::after {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.continue-btn-error {
  color: white;
  font-style: italic;
}

.tos-container {
  width: 100%;
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}